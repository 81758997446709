<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<div v-loading="loading">
			<el-card class="box-card order-list">
				<div slot="header" class="clearfix">
					<el-breadcrumb separator="/">
						<el-breadcrumb-item :to="{ path: '/member/order_list' }">คําสั่งซื้อของฉัน</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/member/order_detail?order_id=' + orderId }">รายละเอียดการสั่งซื้อ</el-breadcrumb-item>
						<el-breadcrumb-item>คืน</el-breadcrumb-item>
					</el-breadcrumb>
				</div>

				<!--ข้อมูลผลิตภัณฑ์-->
				<div class="goods-list">
					<table>
						<tr>
							<td width="62.5%">สินค้า</td>
							<td width="12.5%">ปริมาณ</td>
							<td width="12.5%">ปริมาณ</td>
						</tr>
					</table>
				</div>

				<div class="goods-list">
					<table>
						<tr v-for="(item,index) in refundData.order_goods_info" :key="index">
							<td width="62.5%">
								<div class="goods-info">
									<div class="goods-info-left" v-if="item.sku_image">
										<router-link :to="{ path: '/sku-' + item.sku_id }" target="_blank">
											<img
												class="goods-img"
												:src="$img(item.sku_image, { size: 'mid' })"
												@error="item.sku_image = defaultGoodsImage"/>
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/sku-' + item.sku_id }" target="_blank">
											<div class="goods-name">{{ item.sku_name }}</div>
										</router-link>
									</div>
								</div>
							</td>
							<td width="12.5%" class="goods-num">{{ item.num }}</td>
							<td width="12.5%" class="goods-money">฿{{ item.goods_money }}</td>
						</tr>
					</table>
				</div>
			</el-card>

			<!--ประเภทการคืนเงิน -->
			<div class="item-block">
				<div class="block-text">ประเภทการคืนเงิน</div>

				<div class="pay-type-list">
					<div class="pay-type-item" :class="refundType == 1 ? 'active' : ''" @click="selectRefundType(1)">การคืนเงินไม่จําเป็นต้องส่งคืนสินค้า</div>
					<div v-if="refundData.refund_type.length == 2" class="pay-type-item" :class="refundType == 2 ? 'active' : ''" @click="selectRefundType(2)">การคืนเงินคืนสินค้า</div>
					<div class="clear"></div>
				</div>
			</div>

			<!--มีการคืนเงินเต็มจํานวนแล้ว-->
			<div class="item-block">
				<div class="block-text"></div>

				<el-form ref="form" label-width="80px" class="refund-form">
					<el-form-item label="จํานวนเงินที่คืน"><el-input disabled="" :value="refundData.refund_money"></el-input></el-form-item>
					<el-form-item label="เหตุผลในการคืนเงิน">
						<el-select placeholder="กรุณาเลือก" v-model="refundReason">
							<el-option v-for="(item, itemIndex) in refundData.refund_reason_type" :key="itemIndex" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="คําแนะนําในการคืนเงิน">
						<el-input maxlength="140" show-word-limit resize="none" rows="5" placeholder="โปรดป้อนคําแนะนําในการคืนเงิน（เสริม）" type="textarea" v-model="refundRemark"></el-input>
					</el-form-item>
				</el-form>
			</div>

			<div class="item-block">
				<div class="order-submit"><el-button type="primary" class="el-button--primary" @click="submit">ส่ง</el-button></div>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { refundDataBatch, refund} from '@/api/order/refund';
import { mapGetters } from 'vuex';

export default {
	name: 'refund',
	components: {},
	data: () => {
		return {
			orderGoodsId: '',
			orderId: '',
			refundType: 1,
			refundReason: '',
			refundRemark: '',
			isIphoneX: false,
			refundData: {
				refund_type: [],
				order_goods_info: {
					sku_image: ''
				}
			},
			isSub: false,
			show_type: 0, //สถานะการคืนเงิน 1-รอการคืนเงิน 2-ได้คืนเงินแล้ว
			detail: {
				refund_action: []
			},
			loading: true,
			yes: true
		};
	},
	created() {
		if (this.$route.query.order_goods_id) this.orderGoodsId = this.$route.query.order_goods_id;
		if (this.$route.query.order_id) this.orderId = this.$route.query.order_id;
		this.getRefundData();
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	methods: {
		/**
		 * เลือกวิธีการชําระเงิน
		 * @param {Object} type
		 */
		selectRefundType(type) {
			this.refundType = type;
		},
		getRefundData() {
			refundDataBatch({ order_goods_ids: this.orderGoodsId })
				.then(res => {
					const { code, message, data } = res;
					if (code >= 0) {
						this.refundData = data;
					} else {
						this.$notify({
							message: 'ไม่ได้รับข้อมูลการคืนเงินสําหรับสินค้าเฉพาะรายการ！',
							title: 'คำเตือน',type: 'warning',
							duration: 2000,
							onClose: () => {
								this.$router.push({ path: '/member/activist' });
							}
						});
					}
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.$message.error({
						message: err.message,
						duration: 2000,
						onClose: () => {
							this.$router.push({ path: '/member/activist' });
						}
					});
				});
		},
		submit() {
			if (this.verify()) {
				if (this.isSub) return;
				this.isSub = true;

				let submit_data = {
					order_goods_ids: this.orderGoodsId,
					refund_type: this.refundType,
					refund_reason: this.refundReason,
					refund_remark: this.refundRemark
				};

				refund(submit_data)
					.then(res => {
						const { code, message, data } = res;
						if (code >= 0) {
							this.$notify({
								message: 'การสมัครสําเร็จ！',
								title: 'เรียบร้อยแล้ว',type: 'success',
								duration: 2000,
								onClose: () => {
									this.$router.push({ path: '/member/activist' });
								}
							});
						} else {
							this.isSub = false;
							this.$notify({ message: message, title: 'คำเตือน',type: 'warning' });
						}
					})
					.catch(err => {
						this.$message.error({
							message: err.message,
							duration: 2000,
							onClose: () => {
								this.$router.push({ path: '/member/activist' });
							}
						});
					});
			}
		},
		verify() {
			if (this.refundReason == '') {
				this.$notify({ message: 'โปรดเลือกเหตุผลในการคืนเงิน', title: 'คำเตือน',type: 'warning' });
				return false;
			}
			return true;
		}
	}
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
	box-shadow: unset;
}

.el-card {
	border: 0;
}
.clear {
	clear: both;
}
.item-block {
	padding: 0 15px 1px;
	margin: 10px 0;
	border-radius: 0;
	border: none;
	background: #ffffff;
	.block-text {
		border-color: #eeeeee;
		color: $ns-text-color-black;
		padding: 7px 0;
		border-bottom: 1px;
	}
}
.refund-form {
	width: 350px;
	.el-select {
		width: 100%;
	}
}
.order-submit {
	text-align: center;
	padding: 10px;
}
.goods-list {
	padding: 15px 0;
	table {
		width: 100%;
	}
	.goods-info-left {
		width: 60px;
		height: 60px;
		float: left;
		.goods-img {
			width: 60px;
			height: 60px;
		}
	}
	.goods-info-right {
		float: left;
		height: 60px;
		margin-left: 10px;
		color: $base-color;
		width: 80%;
		.goods-name {
			line-height: 20px;
			padding-top: 10px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
		.goods-spec {
			color: #999;
		}
	}
}
.pay-type-list {
	padding: 20px 0;
}
.pay-type-item {
	display: inline-block;
	border: 2px solid #eeeeee;
	padding: 5px 20px;
	margin-right: 20px;
	cursor: pointer;
}
.pay-type-item.active {
	border-color: $base-color;
}
.status-wrap {
	color: #999;
}
.media-left {
	float: left;
}
.media-right {
	float: right;
	i.rotate {
		transform: rotate(180deg);
		transition: 0.3s;
	}
}
.action-box {
	padding: 10px 0;
}
.action-way {
	float: left;
	color: #999;
}
.head .time {
	float: right;
	color: #999;
}
.record-item {
	margin-bottom: 10px;
}
.order-statistics {
	float: left;
	padding: 10px;
	// color: #999;
}
.el-textarea .el-input__count {
	line-height: 20px;
}
</style>
